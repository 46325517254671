import React from "react"
import footerStyles from "./footer.module.scss"
import { Link } from 'gatsby'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faYoutube, faInstagram, faSnapchat } from '@fortawesome/free-brands-svg-icons'

library.add(faFacebook)
library.add(faTwitter)
library.add(faYoutube)
library.add(faInstagram)
library.add(faSnapchat)

function getYear () {
  return (
    <span>{(new Date()).getFullYear()}</span>
  )
}

const year = getYear()

export default class Footer extends React.Component {
  render() {
    const socialIcons = [
      {
        link: 'https://www.facebook.com/rhythmicfusion/',
        icon: faFacebook,
        title: 'Facebook'
      },
      {
        link: 'https://www.snapchat.com/add/Mike%20Zuniga',
        icon: faSnapchat,
        title: 'Snapchat'
      },
      {
        link: 'https://www.instagram.com/rhythmicfusion/',
        icon: faInstagram,
        title: 'Instagram'
      },
      {
        link: 'https://www.youtube.com/channel/UCL1T7w2bdhbYNo6QDkpXkbQ',
        icon: faYoutube,
        title: 'YouTube'
      },
      {
        link: 'https://twitter.com/RhythmicFusion',
        icon: faTwitter,
        title: 'Twitter'
      },
    ]

    const renderIcons = socialIcons.map((icon, i) => (
      <a
        key={i}
        target="_blank"
        rel="noopener noreferrer"
        href={icon.link}
        title={icon.title}>
          <FontAwesomeIcon icon={icon.icon} />
      </a>
    ))
    return (
      <footer className={footerStyles.footer}>
        <div className="container">
          <div className={footerStyles.footerContent}>
          <div>
            <p>
              <strong style={({'textTransform':'uppercase'})}>RF Dance Center</strong><br />
              1517 N. Main st<br /> 
              Santa Ana, CA 92701<br />
              (949) 689-1835
            </p>
            <p>Copyright &copy;{year} RF Dance. All rights reserved.<br />
              <a href="https://app.termly.io/document/privacy-policy/01de1bb4-2429-4ab3-a5f8-e2116df7eae5" target="_blank" rel="noopener noreferrer">Privacy policy</a><br />
              <Link to="/terms-of-use">Terms of use</Link>
            </p>
          </div>
          <div className={footerStyles.social}>
            <p className="is-hidden-mobile"><strong>Follow us on social media:</strong></p>
            <div className={footerStyles.socialIcons}>
              {renderIcons}
            </div>
            <p style={({'marginTop': '4em'})}><strong>
              Enjoy RF Dance? &nbsp;
              <a 
                href="https://goo.gl/maps/n5k6pfzF84x"
                rel="noopener noreferrer" 
                target="_blank">
                Review us on Google
              </a>
            </strong></p>
          </div>
          </div>
        </div>
      </footer>
    )
  } 
}