import React from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUserCircle, 
  faHeart, 
  faBolt, 
  faFlag, 
  faIdBadge,
  faFile,
  faHome,
  faChild,
  faUsers,
  faDesktop
} from '@fortawesome/free-solid-svg-icons'
import { Link } from "gatsby"
import styles from "./mobile-nav.module.scss"

library.add(faUserCircle)
library.add(faHeart)
library.add(faBolt)
library.add(faFlag)
library.add(faUsers)

library.add(faIdBadge)
library.add(faFile)
library.add(faHome)
library.add(faChild)
library.add(faDesktop)

export default class MobileNav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      default: [
        {
          title: `RF Dance Bachata &amp; Salsa Classes`,
          url: `/classes`,
          icon: `users`,
          type: `a`,
          shortName: `Classes`
        },
        {
          title: `RF Dance Bachata &amp; Salsa Workshops`,
          url: `workshops`,
          icon: `bolt`,
          type: `link`,
          shortName: `Workshops`
        },
        // {
        //   title: `Local Events`,
        //   url: `events`,
        //   icon: `heart`,
        //   type: `link`,
        //   shortName: `Events`
        // },
        {
          title: `Online RF Dance`,
          url: `https://online.rfdance.com`,
          icon: `desktop`,
          type: `external`,
          shortName: `Online`
        },
        {
          title: `RF Dance Private Lessons`,
          url: `privates`,
          icon: `child`,
          type: `link`,
          shortName: `Privates`
        },
        {
          title: `RF Dance Account`,
          url: `account`,
          icon: `user-circle`,
          type: `a`,
          shortName: `Account`
        }
      ],
      team: [
        {
          title: `Dashboard`,
          url: ``,
          icon: `home`,
          type: `link`,
          shortName: `Dashboard`
        },
        {
          title: `Team Documents`,
          url: `documents`,
          icon: `file`,
          type: `link`,
          shortName: `Docs`
        },
        {
          title: `RF Dance Private Lessons`,
          url: `privates`,
          icon: `child`,
          type: `link`,
          shortName: `Privates`
        },
        {
          title: `RF Dance Classes`,
          url: `classes`,
          icon: `child`,
          type: `a`,
          shortName: `Classes`
        },
        {
          title: `RF Dance Account`,
          url: `account`,
          icon: `user-circle`,
          type: `a`,
          shortName: `Account`
        }
      ]
    }
  }
  render() {

    const menu = (this.props.menu && this.props.menu === 'team' ? this.state.team : this.state.default)
    const prefix = (this.props.menu && this.props.menu === 'team' ? `/team-access/` : `/`)
    const navItems = Array.from(menu, (item, index) => {
      if (item.type === 'link') {
        return (
          <Link key={index} to={`${prefix}${item.url}`} title={item.title}>
            <FontAwesomeIcon icon={item.icon} />
            <span className={styles.shortName}>{item.shortName}</span>
          </Link>
        )
      } else {
        return (
          <a key={index} href={`${item.url}`} title={item.title}>
            <FontAwesomeIcon icon={item.icon} />
            <span className={styles.shortName}>{item.shortName}</span>
          </a>

        )
      }
    }
    )

    return (
      <nav className={styles.mobileNav}>
        {navItems}
      </nav>
    )
  }
}