import React from 'react'
import Nav from '../components/nav'
import Footer from '../components/footer'
import SiteMetaData from "../components/sitemeta"
import MobileNav from "../components/mobile-nav"

const Layout = (props) => (
  <div>
      <SiteMetaData 
          title={props.title}
          pathname={props.pathname}
          robots="noindex,nofollow"
          description=""/>
      <Nav navType="team"/>
      <div>
        {props.children}
      </div>
      <Footer />
      <MobileNav menu="team"/>
  </div>
)

export default Layout