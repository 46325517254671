import React from "react"
import Layout from "../../components/layout-team-access"
import styles from "../../components/team-access.module.scss"



class TeamAccess extends React.Component {
  render() {
    return (
      <Layout>
        <div className={styles.teamAccess}>
          <h1>Welcome, teammate!</h1>
          <div className={styles.optionDiv}>
            <a className="is-info button" type="link" href="/team-access/membership">Pay membership dues</a>
          </div>
          <div className={styles.optionDiv}>
            <a className="is-info button" type="a" href="https://www.waiverking.com/print/12748">Sign auto-pay agreement</a>
          </div>
          <div className={styles.optionDiv}>
            <a className="is-info button" type="a" href="https://www.waiverking.com/print/12743">Sign RF Team agreement</a>
          </div>
          <div className={styles.optionDiv}>
            <a className="is-info button" type="link" href="/team-access/documents">Team documents</a>
          </div>
        </div>
      </Layout>

    )
  }
}

export default TeamAccess